import React from "react";
// import styles from "./PrivacyPolicy.module.css";
import "../styles/PrivacyPolicy.scss";
import logo from "../images/logo_opsio.svg";
import { Link } from "gatsby";
import SEO from "../components/seo";
const PrivacyPolicy = () => {
  return (
    <>
      <SEO
        slug="privacy-policy"
        title="Opsio Privacy Policy: Your Data Security and Privacy"
        description="Our Privacy and Cookies Policy explains in detail how Opsio collects, processes, and protects your personal data, ensuring your data's security."
      />
      <div class="container">
        <div>
          <Link to={`/`}>
            <img className="logo" src={logo} alt="Opsio" loading="lazy"/>
          </Link>
        </div>
        <p className="updatedOn">
          Last updated on: <span> 5 December 2022 </span>
        </p>
        <h1 className="main">PRIVACY AND COOKIES POLICY FOR OPSIO</h1>

        <ol type="number" className="ordermain">
          <div>
            <li>INTRODUCTION</li>
            <p>
              Your privacy is important to us. This Privacy and Cookies Policy
              <span> (“Policy”) </span>explains the what, how and why of
              processing information that can be used to directly or indirectly
              identify an individual
              <span>(“Personal Data”)</span> by Opsio AB (hereinafter referred
              to as <span> ‘Opsio’</span>). Opsio seeks to ensure the highest
              level of data privacy when offering its variety of quality
              products and services <span>(“Services”) </span>
              to our customers and subscribers{" "}
              <span>(collectively “You” or “Users”).</span>{" "}
            </p>
            <p>
              {" "}
              Please read this Policy, which provides all relevant information
              about the collection and use of Personal Data by Opsio. This
              Policy applies to the interactions Opsio has with you and the
              Opsio services.
            </p>
            <p>
              {" "}
              All Personal Data are processed in accordance with the General
              Data Protection Regulation (EU) 2016/679 <span>
                (“GDPR”)
              </span>.{" "}
            </p>
            <p>
              For any questions regarding this Policy or any requests regarding
              the processing of Personal Data by Opsio, please contact us at
              info@opsio.se.
            </p>
          </div>
          <br />
          <div>
            <li>GENERAL PRINCIPLES OF PERSONAL DATA PROCESSING</li>

            <p>
              Opsio shall process all Personal Data adhering to the general data
              processing principles:
            </p>
            <ul type="disc">
              <li>
                lawfully, fairly and in a transparent manner in relation to the
                data subject (lawfulness, fairness, and transparency);
              </li>
              <li>
                collect and process Personal Data only for specified, explicit
                and legitimate purposes and not further processed in a manner
                that is incompatible with those purposes (purpose limitation);
              </li>
              <li>
                ensure that Personal Data is adequate, relevant and limited to
                what is necessary for relation to the purposes for which they
                are processed (data minimization);
              </li>
              <li>
                ensure that Personal Data is accurate and, where necessary, kept
                up to date (accuracy);
              </li>
              <li>
                ensure that Personal Data is kept in a form which permits
                identification of data subjects for no longer than is necessary
                for the purposes for which the personal data are processed
                (storage limitation);
              </li>
              <li>
                process Personal Data in a manner that ensures appropriate
                security of the personal data, including protection against
                unauthorized or unlawful processing and against accidental loss,
                destruction or damage, using appropriate technical or
                organizational measures (integrity and confidentiality).
              </li>
            </ul>
            <br />
            <p>
              All and any information stored with Opsio's is treated as strictly
              confidential. All information is stored securely and is accessed
              by qualified and authorized personnel only.
            </p>
          </div>
          <br />
          <div>
            <li>COLLECTION OF PERSONAL DATA</li>
            <p>
              When you use our Services or visit our website or receive any
              service therein, we may require you to provide us with certain
              personally identifiable information, including but not limited to
              name, date of birth, age, device ID, log data, personal
              preferences, location, email address, password, time zone and any
              other information that you may provide. The data that we request
              will be retained by us and used as described in this Policy.{" "}
            </p>
            <p>
              {" "}
              Opsio may use third party services that may collect information
              used to identify you but all such collection of Personal Data
              shall be done only with your consent.
            </p>
          </div>
          <br />
          <div>
            <li>USE OF PERSONAL DATA</li>
            <p>
              We use, store, combine and process information, including Personal
              Data, about you to provide, understand, improve, and develop the
              Opsio Services, create and maintain a trusted and safer
              environment and comply with our legal obligations. The Personal
              Data collected by us are used for the following purposes:
            </p>
            <ul type="disc">
              <li>
                <span>To Identify:</span> Personal identification information is
                collected and processed for the purposes of User identification
                as well use of services. Additionally, this information may be
                used to provide Users with support, letting them know about
                upcoming updates or improvements, providing information
                regarding changes of any terms and conditions including changes
                to this Policy as well as other important information.
              </li>
              <br />
              <li>
                <span>To Create and Maintain Trusted Environment:</span> We
                verify or authenticate information or identifications provided
                by you. We also use collected information to detect and prevent
                fraud, spam, abuse, security incidents, and other illegal and
                harmful activities.
              </li>
              <br />
              <li>
                <span>
                  To Create Aggregated Statistical Data, to Carry out Market
                  Research and Analysis:
                </span>{" "}
                To improve our services to you, we use Personal Data to enable
                ourselves to evaluate our customers’ needs, sales and other
                trends of our activities.
              </li>
              <br />
              <li>
                <span>To Stay Connected:</span> We use information about data
                usage, devices, operating systems to diagnose problems with our
                servers, to administer our Services, to identify characteristics
                of major users so that we may optimize our Services and make
                your browsing and purchasing experience more enjoyable as well
                as ensure that any issues that may be raised by you with regard
                to our services are resolved.
              </li>
              <br />
              <li>
                <span>To Resolve Tickets:</span> We use the information provided
                by you at the time of raising tickets or issues with regard to
                our Services such as your name, email id, password and any
                additional information/attachments that you may provide in order
                to assess your issue with our Services and provide solutions for
                the same.
              </li>
              <br />
              <li>
                <span>To Customize Marketing:</span> To provide more customized
                offers to our Users, we may conduct data analysis based on
                information you provide to us, your interactions with Opsio and
                its Users.
              </li>
              <br />
              <li>
                <span>To Send Service and Billing Messages:</span> Opsio may
                also contact you with important information regarding our
                Services, or your use thereof. For example, we may send you a
                notice (through any of the communication channels available to
                us) if a certain Service is temporarily suspended for
                maintenance; reply to your support ticket or e-mail; send you
                reminders or warnings regarding upcoming or late payments for
                your current or upcoming subscriptions or notify you of material
                changes in our Services.
              </li>
              <br />
              <li>
                <span>To Get Feedback:</span> We may contact you via email to
                invite you to review any Services and/or products you received
                from us in order to collect your feedback and improve our
                Services. We might use an external company, to collect your
                feedback which means that we will share your name, email address
                and reference number for this purpose.
              </li>
              <br />
              <li>
                <span>To Enhance Data Security and to Prevent Fraud:</span> We
                regularly verify the Personal Data collected by us in order to
                detect any fraudulent use of our website and Services. Such
                exercised enable us to weed out potential threats to our systems
                and Services and also to the Personal Data held by us and
                improve our data security.
              </li>
              <br />
              <li>
                <span>To Comply with applicable Laws and Regulations:</span> It
                is important to note that laws in certain regions may require is
                to maintain a database of information regarding use of our
                website and Services by individuals.
              </li>
              <br />
            </ul>
          </div>
          <br />

          <div>
            <li>SOURCES OF PERSONAL DATA</li>
            <p>
              We collect and receive your Personal Data from yourself (including
              your device) as well as from the following sources:
            </p>
            <ul type="disc">
              <li>Your use of our website and Services;</li>
              <li>
                Social network operators (such as LinkedIn, Google, etc.);
              </li>
              <li>Other legal sources.</li>
            </ul>
            <br />
            <p>
              For more information on how the third-parties named above process
              your personal data, you may refer their respective privacy and
              other relevant policies.
            </p>
          </div>
          <br />

          <div>
            <li>RETENTION AND DELETION</li>
            <p>
              We generally retain your personal information for as long as is
              necessary to provide our services and to comply with our legal
              obligations. Where your Personal Data is no longer required, Opsio
              will ensure it is securely deleted.
            </p>
            <p>
              If you would like to stop us using your personal information, you
              may request to erase your Personal Data.
            </p>
            <p>
              Please also note that we may further retain some of your personal
              information in certain cases including:
            </p>
            <ul type="disc">
              <li>
                as long as it is necessary for our legitimate business
                interests, such as fraud detection and prevention and enhancing
                safety;
              </li>
              <li>
                the extent necessary to comply with our legal obligations. Opsio
                may keep some of your information for tax, legal reporting and
                auditing obligations;
              </li>
              <li>to resolve disputes;</li>
              <li>
                to enforce our agreements and/or pursue or protect our
                legitimate interests;
              </li>
              <li>
                as we protect Opsio Services from accidental or malicious loss
                and destruction, residual copies of your personal information
                may not be removed from our backup systems for a limited period
                of time.
              </li>
            </ul>
          </div>
          <br />

          <div>
            <li>SECURITY</li>
            <p>
              Opsio has implemented security measures designed to protect the
              Personal Data you share with us, including physical, electronic
              and procedural measures. Among other things, we offer HTTPS secure
              access to most areas on our Services. We also regularly monitor
              our systems for possible vulnerabilities and attacks, and
              regularly seek new ways and Third-Party Services for further
              enhancing the security of our Services and protection of our
              Visitors' and Users' privacy.
            </p>
            <p>
              Regardless of the measures and efforts taken by Opsio, we cannot
              and do not guarantee the absolute protection and security of your
              Personal Data.
            </p>
            <p>
              We therefore encourage you to take necessary security measures of
              your own while using our website and Services and avoid providing
              us or anyone with any sensitive information of which you believe
              its disclosure could cause you substantial or irreparable harm.
            </p>
            <p>
              If you have any questions regarding the security of our Services,
              you are welcome to contact us at info@opsio.se.
            </p>
          </div>
          <br />
          <div>
            <li>YOUR RIGHTS</li>
            <p>
              You are entitled to a range of rights regarding the protection of
              your Personal Data, which are subject to limitations, restrictions
              and conditions as laid down in GDPR and applicable law. Those
              rights are:
            </p>
            <ul type="disc">
              <li>the right to access the information we process about you;</li>
              <li>
                the right to rectify incorrect/inaccurate information about you;
              </li>
              <li>
                the right to transfer all or part of the information collected
                about you to you or another data controller, where technically
                feasible (the right to data portability; with limitations and
                restrictions as specified in the EU General Data Protection
                Regulation);
              </li>
              <li>
                the right to erase any data concerning you. Users may demand
                erasure of data without undue delay for legitimate reasons, e.g.
                where data is no longer necessary for the purposes it was
                collected, or where the data has been unlawfully processed;
              </li>
              <li>
                the right to the restriction of data processing. Users, for
                legitimate purposes, may obtain restriction of data processing;
              </li>
              <li>
                the right to object to the processing of Personal Data when
                processing is carried out on the basis of legitimate interest,
                as well as in cases of use of your personal data for direct
                marketing purposes, as specified above in this Policy.
              </li>
            </ul>
            <br />
            <p>
              Users have the right to lodge a complaint with the national Data
              Protection Agency in their country of residence in the event where
              their rights may have been infringed. However, we recommend
              attempting to reach a peaceful resolution of the possible dispute
              by contacting us first.
            </p>
          </div>
          <br />

          <div>
            <li>ACCEPTANCE OF THIS POLICY</li>
            <p>
              We assume that all Users of Opsio website and Services have
              carefully read this Policy and agree to its contents. If someone
              does not agree with this Policy, they should refrain from using
              our website and Services. We reserve the right to change our
              Policy at any time. Continued use of Opsio’s website and Services
              implies acceptance of the revised Policy.
            </p>
            <p>
              We will ask for your consent before using information for a
              purpose other than those set out in this Policy.
            </p>
          </div>
          <br />

          <div>
            <li>COOKIES AND SIMILAR TECHNOLOGY</li>
            <p>
              Cookies are small text files placed on your device to store data
              that can be recalled by a web server in the domain that placed the
              cookie. We use cookies and similar technologies for storing and
              honoring your preferences and settings, enabling you to sign in,
              providing interest-based advertising, combating fraud, analyzing
              how our Services perform and fulfilling other legitimate purposes.{" "}
            </p>
          </div>
          <br />

          <div>
            <li>TYPES OF COOKIES</li>
            <p>
              Opsio uses functionality cookies to enhance its website’s
              performance and functionality and tracking cookies which are a
              specialized type of cookie that can be shared by more than one
              website or service for legitimate marketing and advertising
              purposes.{" "}
            </p>
          </div>
          <br />

          <div>
            <li>AMENDMENTS</li>
            <p>
              Our Policy may change from time to time. We will post any Policy
              changes on our website and, if the changes are significant, we may
              consider providing a more explicit notice (including, for certain
              services, email notification of Policy changes).
            </p>
          </div>
          <br />

          <div>
            <li>GOVERNING LAW</li>
            <p>
              This Policy shall be governed by and construed in accordance with
              the laws prevailing in Sweden.
            </p>
          </div>
          <br />

          <div>
            <li>CONTACT</li>
            <p>
              For all your requests or comments concerned with this Privacy
              Policy, please contact: info@opsio.se.
            </p>
          </div>
        </ol>
        <br />

        <hr />
        <div>
          <span className=" text-decoration-underline">
            <b>Log of Changes:</b>
          </span>
          <br />
          <br />
          <span className="text-decoration-underline">Version 1.1:</span>
          <ul>
            <li>
              <p>
                Amended Clause 3 to add additional types of personal data such
                as password, time zone and any other information that you may
                provide.
              </p>
            </li>
            <li>
              <p>
                Amended Clause 4 section “To Stay Connected” to add the words
                “as well as ensure that any issues that may be raised by you
                with regard to our services are resolved.”
              </p>
            </li>
            <li>
              <p>
                Amended Clause 4 to add “To Resolve Tickets” as an additional
                use of Personal Data.
              </p>
            </li>
          </ul>
        </div>
        <br />
      </div>
    </>
  );
};

export default PrivacyPolicy;
